import './App.css';
//import { Outlet, Link } from 'react-router-dom';

function App() {
  return (
    <div style={{fontFamily: "Montserrat", fontWeight: 500}}>
      <h1>Nick Yuan</h1>
      <h2>Technical Leader</h2>


      <div style={{ marginLeft: "24px", lineHeight: "1.5" }}>
        <a href="/Nicholas_Yuan_resume.pdf" download={true}>
          Resume
        </a>

        <br/>

        <a href="https://github.com/Nick-Yawn" target="_blank" rel="noreferrer">
          GitHub
        </a>

        <br/>

        <a href="https://www.linkedin.com/in/nick-yawn/" target="_blank" rel="noreferrer">
          LinkedIn
        </a>

        <br/>

        Latest LinkedIn Article:{" "}
        <a href="https://www.linkedin.com/pulse/engineering-lessons-from-failed-acquisition-nick-yuan-8gmkc/" target="_blank" rel="noreferrer">
          Engineering Lessons from a Failed Acquisition
        </a>

        <br/>

        Latest Medium Article:{" "}
        <a href="https://medium.com/@Nick_Yawn/hosting-your-react-app-on-s3-with-https-on-a-custom-domain-updated-june-2022-6fc71115a83c" target="_blank" rel="noreferrer">
          React app hosting on AWS S3 with HTTPS and a custom domain
        </a>

        <br/>

        Latest Youtube Video:{" "}
        <a href="https://youtu.be/BvsL7YYhUvc" target="_blank" rel="noreferrer">
          Using react-transition-group to animate component mounting / unmounting
        </a>

      </div>
        <p style={{paddingLeft: 10}}>
          Updated October 21, 2024
        </p>

    </div>
  );
}

export default App;
